<script lang="ts">
export default {
    name: 'ResetPassword',
    setup() {
        const runtimeConfig = useRuntimeConfig()
        const route = useRoute()

        definePageMeta({ layout: 'empty' })
        useHead({
            title: 'Mot de passe oublié',
            meta: [
                { name: 'description', content: 'Mot de passe oublié' },
            ],
        })

        return {
            runtimeConfig,
            route,
        }
    },
    data: () => ({
        password: null,
        passwordConfirm: null,
        resetting: false,
        visible: false,
    }),
    mounted() {
    },
    methods: {
        async resetPassword() {
            this.resetting = true
            const route = {
                url: 'password',
                method: 'POST',
                body: {
                    token: this.route.params.token,
                    password: this.password,
                    password_confirmation: this.passwordConfirm,
                    email: this.route.query.email,
                },
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            }
            try {
                await this.$api.setRequest(route, true)
                this.$showToaster({ title: 'Réinitialisation', text: `Votre nouveau mot de passe est maintenant actif`, color: 'success', icon: 'mdi-check-circle', timer: -1 })
            }
            catch (error) {
                this.$showToaster({ title: 'Échec de réinitialisation', text: `Désolé, votre mot de passe n'a pas pu être changé : ${error.data.message}`, color: 'error', icon: 'mdi-alert-circle', timer: -1 })
            }
            this.resetting = false
        },
    },
}
</script>

<template>
    <div>
        <v-container class="pa-0">
            <v-row
                class="h-screen"
                justify="center"
                align="center"
            >
                <v-col cols="12">
                    <v-card
                        class="mx-auto pa-10"
                        elevation="8"
                        max-width="600"
                        rounded="lg"
                    >
                        <v-form
                            validate-on="submit lazy"
                            @submit.prevent="resetPassword"
                        >
                            <div class="w-100 text-center">
                                <img
                                    class="mx-auto"
                                    src="@nuxtcommon/assets/images/logo.png"
                                    alt="Pildom"
                                >
                            </div>

                            <p class="text-subtitle-2 text-center py-6 font-italic">
                                Renseigner votre nouveau mot de passe
                            </p>

                            <div class="text-subtitle-1 text-medium-emphasis">
                                Mot de passe
                            </div>
                            <div class="text-caption text-medium-emphasis">
                                Le mot de passe doit contenir au moins un chiffre, une lettre minuscule, une lettre majuscule, un caractère spécial et doit avoir une longueur minimale de 14 caractères.
                            </div>
                            <v-text-field
                                v-model="password"
                                density="compact"
                                prepend-inner-icon="mdi-lock-outline"
                                variant="outlined"
                                :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                                :type="visible ? 'text' : 'password'"
                                @click:append-inner="visible = !visible"
                            />

                            <div class="text-subtitle-1 text-medium-emphasis">
                                Confirmation du mot de passe
                            </div>

                            <v-text-field
                                v-model="passwordConfirm"
                                density="compact"
                                prepend-inner-icon="mdi-lock-outline"
                                variant="outlined"
                                :type="visible ? 'text' : 'password'"
                            />

                            <v-btn
                                type="submit"
                                :loading="resetting"
                                block
                                class="my-4"
                                color="primary"
                                size="large"
                            >
                                Changer le mot de passe
                            </v-btn>
                            <v-btn
                                block
                                class="my-4"
                                color="primary"
                                size="large"
                                variant="tonal"
                                @click="$router.replace('/#connexion')"
                            >
                                Retourner à l'écran de connexion
                            </v-btn>
                        </v-form>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
